<template>
  <ClaimForm
    :promotionId="$route.params.id"
    @stepChange="$vuetify.goTo(0)"
    @onCancel="onCancel"
    :claimId="$route.query.claimId"
    :public="false"
    :enableOcr="true"
    :showExampleImages="true"
    :strings="{ mask: [] }"
    :reminders="[
      {
        text:
          'For all Sony product, except Xperia, the middle-seven digits should be entered (e.g. S01-4036705-B -> 4036705)'
      },
      { text: 'For Xperia product, the full IMEI is required (e.g., 35242794-019573-7)' }
    ]"
  >
    <template v-slot:example-images>
      <v-img src="@/assets/all-sony-product-except-xperia.png" />
      <v-img src="@/assets/for-xperia-only.png" />
    </template>
  </ClaimForm>
</template>

<script>
import ClaimForm from "@/gapp-components/components/forms/claim/ClaimForm.vue";
import { mapGetters } from "vuex";

import moment from "moment-timezone";
import Vue from "vue";

export default {
  components: { ClaimForm },
  name: "CprClaimOcr",
  metaInfo: {
    title: "Claim"
  },
  data: () => ({
    menuOpen: false,
    selected: null,
    items: [{ title: "Item 1" }, { title: "Item 2" }, { title: "Item 3" }],
    mini: true,
    ocrScanCancelled: false,
    showRightPanel: true,
    show: true,
    testRender: true,
    showAddresses: false,
    step: 0,

    valid: {
      step1: false,
      step2: false,
      step3: false,
      uploadForm: false
    },
    isBusy: false,
    isEditing: true,
    promotionId: 0,
    errors: {},
    serialNumberRegex: "",

    promotion: {},

    claimFields: [],
    claimFieldsByRow: [],
    claimFieldValues: {},
    claimFieldsAlreadyLoaded: false,
    selectedPromotionProducts: [],
    claimProducts: [],
    claimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Product",
        align: "left"
      },
      {
        value: "serialNumber",
        text: "Details",
        align: "left",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "right",
        sortable: false
      }
    ],

    claimSubmitted: false,

    claimStage: null,

    claimUploads: {
      existing: [],
      deleted: []
    },
    documentation: {
      existing: [],
      deleted: []
    },
    ocrAddress: null,
    availableAddresses: [],
    rules: {
      required: [v => !!v || "Field is required"],
      participant: [v => typeof v == "object" || "Participant is required"],
      quantity: [v => !!v || "Quantity is required"]
    },

    form: {},
    uploads: {},
    uploadedFilesDialog: false,
    participantSelectedToClaimOnBehalf: null,
    claimNumber: "",
    q1: false,
    zeroSales: false,
    zeroSalesItems: [],
    selectedZeroSalesPeriod: null,
    formattedZeroSalesPeriod: null,
    saveEndUserForLater: true,
    selectedEndUser: null,

    preferredAwardVehicle: undefined,
    availableAwardVehicles: [],
    preferredAwardVehicleDialog: false,

    participantSkipClaimDocumentation: false,
    promotionSkipClaimDocumentation: false,
    promotionTypeDocumentationRequired: false,
    searchInput: null,
    loadingOcrInvoiceScan: false,
    loadingOcrDocumentQuery: false,
    ocrMaxAttempts: 3,
    ocrAttempts: 0,
    ocrInvoiceScanToClaimFieldMappings: [
      {
        property: "invoiceReceiptDate",
        claimFieldTypeName: "INVOICE_DATE_TYPE",
        convert: v => moment.tz(v.split("T")[0], "YYYY-MM-DD", "America/New_York").format()
      },
      {
        property: "invoiceReceiptId",
        claimFieldTypeName: "INVOICE_NUMBER_TYPE",
        convert: v => v
      },
      { property: "total", claimFieldTypeName: "INVOICE_AMOUNT_TYPE", convert: v => v.toString() },
      {
        property: "receiverFirstName",
        claimFieldTypeName: "END_USER_FIRST_NAME_TYPE",
        convert: v => v
      },
      {
        property: "receiverLastName",
        claimFieldTypeName: "END_USER_LAST_NAME_TYPE",
        convert: v => v
      },
      {
        property: "receiverOrganizationName",
        claimFieldTypeName: "END_USER_ORGANIZATION_NAME_TYPE",
        convert: v => v
      },
      {
        property: "receiverPhone",
        claimFieldTypeName: "END_USER_PHONE_NUMBER1_TYPE",
        convert: v => v
      }
    ],
    addressToClaimFieldMappings: [
      {
        addressField: "address1",
        claimFieldTypeName: "END_USER_ADDRESS1_TYPE",
        convert: v => v
      },
      {
        addressField: "address2",
        claimFieldTypeName: "END_USER_ADDRESS2_TYPE",
        convert: v => v
      },
      {
        addressField: "city",
        claimFieldTypeName: "END_USER_CITY_TYPE",
        convert: v => v
      },
      {
        addressField: "region",
        claimFieldTypeName: "END_USER_REGION_TYPE",
        convert: v => v
      },
      {
        addressField: "country",
        claimFieldTypeName: "END_USER_COUNTRY_TYPE",
        convert: v => v
      },
      {
        addressField: "postalCode",
        claimFieldTypeName: "END_USER_POSTAL_CODE_TYPE",
        convert: v => v
      }
    ],
    ocrDocumentQueryToClaimFieldMappings: [
      {
        query: "What is the customer first name?",
        claimFieldTypeName: "END_USER_FIRST_NAME_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer last name?",
        claimFieldTypeName: "END_USER_LAST_NAME_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer company?",
        claimFieldTypeName: "END_USER_ORGANIZATION_NAME_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to address?",
        claimFieldTypeName: "END_USER_ADDRESS1_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to city?",
        claimFieldTypeName: "END_USER_CITY_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to state?",
        claimFieldTypeName: "END_USER_REGION_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to country?",
        claimFieldTypeName: "END_USER_COUNTRY_TYPE",
        convert: v => v
      },
      {
        query: "What is the ship to zip code?",
        claimFieldTypeName: "END_USER_POSTAL_CODE_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer email?",
        claimFieldTypeName: "END_USER_EMAIL_TYPE",
        convert: v => v
      },
      {
        query: "What is the customer phone number?",
        claimFieldTypeName: "END_USER_PHONE_NUMBER1_TYPE",
        convert: v => v
      }
    ],

    serialNumberConversion: serialNumber => {
      if (!serialNumber) return;
      let s01removed = serialNumber.replace(/^S01/gi, "");
      let cleanSerialNumber = s01removed.substring(0, Math.min(7, s01removed.length));
      return cleanSerialNumber;
    }
  }),
  created() {
    this.promotionId = parseInt(this.$route.params.id);
    this.participantSkipClaimDocumentation = this.selectedParticipant.skipClaimDocumentation;
    this.breadcrumb();
    this.loadZeroSalesMonths();
  },

  watch: {
    claimUploads: {
      handler() {
        this.$refs.uploadForm.validate();
      },
      deep: true
    },
    selectedZeroSalesPeriod() {
      this.formattedZeroSalesPeriod = moment
        .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
        .format("MMMM YYYY");
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"]),

    endUserRelated() {
      let result = false;
      for (let field of this.claimFields) {
        if (field.claimFieldType.name.startsWith("END_USER")) {
          result = true;
        }
      }
      return result;
    },
    claimProductHeadersForFlatTable() {
      return Object.assign([], this.claimProductHeaders).pop();
    },
    validStep3() {
      return this.valid && this.valid.step3 && this.claimProducts && this.claimProducts.length != 0;
    },
    documentationRequired() {
      return (
        !this.participantSkipClaimDocumentation &&
        !this.promotionSkipClaimDocumentation &&
        this.promotionTypeDocumentationRequired
      );
    },
    participantToClaim() {
      return this.participantSelectedToClaimOnBehalf && this.participantSelectedToClaimOnBehalf.id
        ? this.participantSelectedToClaimOnBehalf.id
        : this.selectedParticipant.id;
    },
    totalProductsClaimed() {
      let amount = 0;
      for (let cp of this.claimProducts) {
        if (cp.quantity) {
          amount += eval(cp.quantity);
        } else {
          amount += 1;
        }
      }
      return amount;
    },
    userHasPrivilegeToClaimOnHalfofAnyone() {
      return this.$privilege.hasPrivilege("CLAIM_ON_BEHALF_OF_ANYONE");
    }
  },
  methods: {
    claimFieldValueDescription(claimFieldId) {
      if (!claimFieldId) return;
      let claimField = this.claimFieldValues[claimFieldId];
      let claimFieldType = this.claimFields[this.claimFields.findIndex(elem => elem.id == claimFieldId)].claimFieldType;

      if (claimField) {
        if (claimFieldType.name == "DATE_TYPE" || claimFieldType.name == "INVOICE_DATE_TYPE") {
          return moment.tz(claimField.split("T")[0], "YYYY-MM-DD", "America/New_York").format("YYYY-MM-DD");
        } else {
          return claimField.description ? claimField.description : claimField;
        }
      } else {
        return "N/A";
      }
    },
    loadAddress(address, initialLoad) {
      this.addressToClaimFieldMappings.forEach(mapping => {
        let claimField = this.findClaimFieldByClaimFieldType(this.claimFields, mapping.claimFieldTypeName);
        if (claimField) {
          this.$set(this.claimFieldValues, claimField.id, mapping.convert(address[mapping.addressField]));
          if (initialLoad) {
            claimField.ocrPopulated = true;
          }
        }
      });
      this.$forceUpdate();
    },
    validateAllForms() {
      this.$refs.step2Form.validate();
      this.$refs.step3Form.validate();
    },
    findClaimFieldByClaimFieldType(claimFields, claimFieldTypeName) {
      let returnValue = null;
      claimFields.forEach(cf => {
        if (cf.claimFieldType.name == claimFieldTypeName) {
          returnValue = cf;
        }
      });
      return returnValue;
    },
    processOcrInvoiceScanResult(ocrResult) {
      for (let productLine of ocrResult.productLines) {
        this.claimProducts.push({
          promotionProduct: this.promotion.promotionProducts.find(
            pp => pp.product.productKey == productLine.productKey
          ),
          quantity: productLine.quantity ? productLine.quantity : 1,
          serialNumber: this.serialNumberConversion(productLine.serialNumber),
          ocrPopulated: true
        });
      }
      this.$forceUpdate();

      this.ocrInvoiceScanToClaimFieldMappings.forEach(mapping => {
        let claimField = this.findClaimFieldByClaimFieldType(this.claimFields, mapping.claimFieldTypeName);
        if (ocrResult[mapping.property] && claimField) {
          Vue.set(this.claimFieldValues, claimField.id, mapping.convert(ocrResult[mapping.property]));
          Vue.set(claimField, "ocrPopulated", true);
        }
      });
    },

    ocrInvoice(upload) {
      this.ocrScanCancelled = false;
      this.ocrPromise = this.$ocr
        .invoiceScan(this.promotion.id, upload.id)
        .then(ocrResult => {
          if (this.ocrScanCancelled) return Promise.resolve();
          // I'm thinking about moving many of the following properties to a more general
          // instance like main.js but I'm not sure how yet.
          this.processOcrInvoiceScanResult(ocrResult);
          this.$refs.step3Form.validate();
          this.availableAddresses = [];
          if (ocrResult && ocrResult.receiverAddress && ocrResult.receiverAddress.length > 0) {
            const promotionAddresses = ocrResult.receiverAddress.filter(address => address.trim() !== "");

            return promotionAddresses
              .reduce((prev, address) => {
                return prev.then(() => {
                  return this.$location.parseAddressForPromotion(address, this.promotion.id).then(data => {
                    this.availableAddresses.push(data);
                  });
                });
              }, Promise.resolve())
              .then(() => {
                if (this.availableAddresses.length > 0) {
                  // load the first address (which is receiver address)
                  this.loadAddress(this.availableAddresses[0], true);
                }
                this.loadingOcrInvoiceScan = false;
                this.step++;
                return Promise.resolve();
              });
          } else {
            this.loadingOcrInvoiceScan = false;
            this.step++;
          }
        })
        .finally(() => {
          this.$refs.step2Form.validate();
        })
        .catch(error => {
          console.log(error);
          this.loadingOcrInvoiceScan = false;
          this.step++;
        });
    },
    showLoadingOcrInvoiceScan() {
      this.loadingOcrInvoiceScan = true;
      this.$forceUpdate();
    },
    processOcrDocumentQueryResult(ocrResult, upload) {
      if (ocrResult.status == "READY" && this.ocrAttempts < this.ocrMaxAttempts) {
        let claimField;
        for (let answer in ocrResult.answers) {
          let answersList = ocrResult.answers[answer];
          let queryMapping = this.ocrDocumentQueryToClaimFieldMappings.find(elem => elem.query == answer);
          if (queryMapping) {
            claimField = this.findClaimFieldByClaimFieldType(queryMapping.claimFieldTypeName);
            if (claimField) {
              this.$set(this.claimFieldValues, claimField.id, queryMapping.convert(answersList[0]));
              this.$set(claimField, "ocrPopulated", true);
            }
          }
        }
        this.loadingOcrDocumentQuery = false;
      } else if (ocrResult.status == "PENDING" && this.ocrAttempts < this.ocrMaxAttempts) {
        this.ocrAttempts++;
        setTimeout(() => {
          this.$api.post("/api/ocr/checkDocumentQuery/" + this.promotion.id + "/" + upload.id).then(({ data }) => {
            this.processOcrDocumentQueryResult(data, upload);
          });
        }, 5000);
      } else if (this.ocrAttempts == this.ocrMaxAttempts) {
        console.log("error");
        this.loadingOcrDocumentQuery = false;
      }

      this.$forceUpdate();
    },

    updateSonyDealerFieldsVisibility(v) {
      let selectedItem = v.selectedItem;
      let other = v.other;
      for (let claimField of this.claimFields) {
        if (claimField.name && claimField.name.startsWith("Sony Dealer") && claimField.name.trim() != "Sony Dealer") {
          claimField.hidden = !v.other;
          if (!other) {
            if (selectedItem) {
              switch (claimField.name) {
                case "Sony Dealer Name":
                  this.claimFieldValues[claimField.id] = selectedItem.name;
                  break;
                case "Sony Dealer Address 1":
                  this.claimFieldValues[claimField.id] = selectedItem.address.address1;
                  break;
                case "Sony Dealer Address 2":
                  this.claimFieldValues[claimField.id] = selectedItem.address.address2;
                  break;
                case "Sony Dealer City":
                  this.claimFieldValues[claimField.id] = selectedItem.address.city;
                  break;
                case "Sony Dealer State / Province":
                  this.claimFieldValues[claimField.id] = selectedItem.address.region;
                  break;
                case "Sony Dealer Country":
                  this.claimFieldValues[claimField.id] = selectedItem.address.country;
                  break;
                case "Sony Dealer Postal Code":
                  this.claimFieldValues[claimField.id] = selectedItem.address.postalCode;
                  break;
              }
            }
          }
          if (other || !selectedItem) {
            this.claimFieldValues[claimField.id] = null;
          }
        }
      }
      this.$forceUpdate();
    },
    onNextStep() {
      this.step++;
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;
      this.claimProducts.forEach(element => {
        if (
          element.serialNumber &&
          element.promotionProduct.product.id == item.promotionProduct.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return "Serial number duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.promotionProduct.enableSerialNumbers) {
        if (item.serialNumber) {
          if (item.promotionProduct.product.serialNumberRegex) {
            let regex = RegExp("^" + item.promotionProduct.product.serialNumberRegex);
            if (regex.test(item.serialNumber)) {
              return true;
            } else {
              return "Invalid Serial Number";
            }
          } else {
            return true;
          }
        } else {
          return "Serial Number required";
        }
      } else {
        return false;
      }
    },
    requireProduct() {
      if (this.claimProducts.length > 0) {
        return true;
      } else {
        return "At least 1 product is required";
      }
    },
    requireUpload() {
      if (this.claimUploads.existing.length > 0) {
        return true;
      } else {
        return "At least 1 upload is required";
      }
    },
    onAddProduct() {
      let promotionProducts = this.claimProducts;
      this.selectedPromotionProducts.some(promotionProduct => {
        let found = promotionProducts.findIndex(
          needle => needle.promotionProduct.product.id == promotionProduct.product.id
        );
        if (found == -1) {
          this.$api.get("/api/promotionProducts/" + promotionProduct.id + "/allowPriceEntry").then(res => {
            promotionProduct.allowPriceEntry = res.data;
            promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
            this.selectedPromotionProducts = [];
            this.$set(this, "claimProducts", promotionProducts);
          });
        } else {
          if (promotionProduct.enableSerialNumbers) {
            promotionProducts = promotionProducts.concat([{ promotionProduct: promotionProduct }]);
          }
        }
      });
      this.claimProducts = promotionProducts;
      this.selectedPromotionProducts = [];
    },
    onDeleteProduct(claimProduct) {
      const index = this.claimProducts.indexOf(claimProduct);
      this.claimProducts.splice(index, 1);
    },
    onDuplicateProduct(claimProduct) {
      let claimProductCopy = { ...claimProduct };
      claimProductCopy.serialNumber = "";
      claimProductCopy.ocrPopulated = false;
      this.claimProducts = this.claimProducts.concat(claimProductCopy);
    },
    onSubmit() {
      this.isBusy = true;
      this.errors = {};

      let postForm = {};
      postForm.promotionKey = this.promotion.promotionKey;
      postForm.browserSource = window.location.href;
      postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();
      if (this.participantSelectedToClaimOnBehalf && this.participantSelectedToClaimOnBehalf.id) {
        postForm.participant = { id: this.participantSelectedToClaimOnBehalf.id };
      } else {
        postForm.participant = { id: this.selectedParticipant.id };
      }

      if (this.q1 == "yes") {
        postForm.zeroSales = true;
        postForm.zeroSalesDate = moment
          .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        postForm.uploads = [];
        postForm.claimProducts = [];
        postForm.claimFieldValues = [];
      } else {
        // postForm.claimStage - allow this to be determined
        postForm.zeroSales = false;
        postForm.uploads =
          this.documentation.existing && this.documentation.existing.length > 0
            ? this.claimUploads.existing.concat(this.documentation.existing)
            : this.claimUploads.existing;
        postForm.claimProducts = this.getClaimProducts();
        postForm.claimFieldValues = this.getClaimFieldValues();
      }

      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "CLAIM_SUBMISSION"
      };
      postForm.saveEndUserForLater = this.selectedEndUser ? false : this.saveEndUserForLater;
      if (this.$route.query.claimId == undefined) {
        this.$api
          .post("/api/claims/submit", postForm)
          .then(({ data }) => {
            this.form = data;
            this.claimNumber = data.claimKey;
            this.isBusy = false;
            this.claimSubmitted = true;
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
            this.$vuetify.goTo(0);
            this.isBusy = false;
            setTimeout(() => {
              this.validateAllForms();
            }, 1000);
          });
      } else {
        postForm.submitAfterUpdate = true;
        this.$api
          .post("/api/claims/" + this.$route.query.claimId + "/update", postForm)
          .then(({ data }) => {
            this.form = data;
            this.claimNumber = data.claimKey;
            this.isBusy = false;
            this.claimSubmitted = true;
          })
          .catch(error => {
            this.errors = this.$api.getErrorsFromResponse(error);
            this.$vuetify.goTo(0);
            this.isBusy = false;
            setTimeout(() => {
              this.validateAllForms();
            }, 1000);
          });
      }
    },
    getReferrerFromQueryString() {
      var queryString = window.location.search.substring(1);
      var params = new URLSearchParams(queryString);
      if (params.has("referrer")) {
        return params.get("referrer");
      } else {
        return null;
      }
    },
    getClaimFieldValues() {
      const claimFieldsClone = this.claimFields.slice();
      let cfValues = [];
      claimFieldsClone.map(claimField => {
        let cfValue = {
          claimField: { id: claimField.id },
          value:
            this.claimFieldValues[claimField.id] && this.claimFieldValues[claimField.id].name
              ? this.claimFieldValues[claimField.id].name.trim()
              : this.claimFieldValues[claimField.id]
              ? this.claimFieldValues[claimField.id].trim()
              : this.claimFieldValues[claimField.id]
        };
        cfValues.push(cfValue);
      });

      return cfValues;
    },

    getClaimProducts() {
      let claimProducts = [];
      this.claimProducts.forEach(claimProduct => {
        let cp = Object.assign({}, claimProduct);
        cp.priceAmountCurrency = { name: this.promotion.country.currencyName };
        cp.promotionProduct = { id: claimProduct.promotionProduct.id };
        claimProducts.push(cp);
      });

      return claimProducts;
    },

    fetchPreferredAwardVehicle() {
      this.$api
        .get("/api/participants/" + this.participantToClaim + "/preferredAwardVehicle")
        .then(({ data }) => {
          this.preferredAwardVehicle = data;
        })
        .catch(() => {
          this.preferredAwardVehicle = undefined;
        });
    },
    fetchAvailableAwardVehicles() {
      return this.$awardvehicle.getAvailableAwardVehicles(this.participantToClaim, this.promotion).then(avs => {
        this.availableAwardVehicles = avs;
      });
    },
    onChangeParticipantClaimOnBehalf() {
      Promise.all([this.fetchPreferredAwardVehicle(), this.fetchAvailableAwardVehicles()]);
    },
    fetchData() {
      this.isBusy = true;

      this.$api
        .get("/api/promotions/" + this.$route.params.id)
        .then(({ data }) => {
          this.promotion = data;
          this.promotionSkipClaimDocumentation = this.promotion.skipClaimDocumentation;
          this.breadcrumb();

          return Promise.all([
            this.fetchPreferredAwardVehicle(),

            this.fetchAvailableAwardVehicles(),

            this.$api.getRelatedObject("promotionType", data).then(({ data }) => {
              this.promotion.promotionType = data;
              if (this.promotion.promotionType.uploadLimit > 0) {
                this.promotionTypeDocumentationRequired = true;
              }
            }),

            this.$api.getRelatedObject("claimType", data).then(({ data }) => {
              this.promotion.claimType = data;
              // claim stage
              this.$api
                .get("/api/claimStages/byClaimTypeId/" + data.id + "?sort=rankOrder,ASC&size=1")
                .then(({ data }) => {
                  this.claimStage = data.content[0];
                });

              // claim fields
              this.$api
                .get("/api/claimFields/byClaimTypeId/" + data.id + "?sort=rankOrder,ASC&size=200")
                .then(({ data }) => {
                  this.claimFields = data.content;
                  this.claimFieldsByRow = [];
                  let rowCount = -1;
                  let colCount = 0;
                  this.claimFields.forEach((claimField, i) => {
                    if (!claimField.cols || claimField.cols < 1 || claimField.cols > 12) {
                      claimField.cols = 12;
                    }
                    if (i == 0 || this.claimFields[i].category != this.claimFields[i - 1].category) {
                      claimField.newCategory = true;
                    } else {
                      claimField.newCategory = false;
                    }
                    if (claimField.newCategory || colCount + claimField.cols > 12) {
                      rowCount++;
                      colCount = claimField.cols;
                    } else {
                      colCount += claimField.cols;
                    }
                    if (!this.claimFieldsByRow[rowCount]) {
                      this.claimFieldsByRow[rowCount] = [];
                    }
                    this.claimFieldsByRow[rowCount].push(claimField);

                    if (claimField.defaultValue && claimField.defaultValue.length > 0) {
                      Vue.set(this.claimFieldValues, claimField.id, claimField.defaultValue);
                    }
                  });
                });
            }),

            this.$api
              .post("/api/promotionProducts/search?size=500&page=0&nestedSort=product.productKey,ASC", {
                promotion: { id: data.id },
                isEffective: true
              })
              .then(({ data }) => {
                this.promotion.promotionProducts = data.content.filter(item => item.effective == true);
              }),

            this.$api.post("/api/promotionDeadlines/search", { promotion: { id: data.id } }).then(({ data }) => {
              this.promotion.promotionDeadlines = data;
            }),

            this.fetchClaimData(),
            this.$api.get("/api/promotions/" + this.$route.params.id + "/uploads/").then(({ data }) => {
              this.uploads = data._embedded.uploads;
            })
          ])
            .then(() => {
              this.zeroSales = this.promotion && this.promotion.claimType && this.promotion.claimType.allowZeroSales;
              if (!this.zeroSales && !this.preferredAwardVehicle && this.availableAwardVehicles.length > 1) {
                this.preferredAwardVehicleDialog = true;
              }
            })
            .finally(() => {
              this.isBusy = false;
            });
        })
        .then(() => {
          this.step = 1;
        });
    },
    fetchClaimData() {
      if (this.$route.query.claimId != undefined && this.$route.query.claimId != 0) {
        return this.$api.get("/api/claims/" + this.$route.query.claimId).then(({ data }) => {
          Promise.all([
            this.$api.get("/api/claimFieldValues/byClaimId/" + data.id + "?size=100").then(({ data }) => {
              data.content.forEach(claimFieldValue => {
                Vue.set(this.claimFieldValues, claimFieldValue.claimFieldId, claimFieldValue.value);
              });
            }),
            this.$api.get("/api/claimProducts/byClaimId/" + data.id + "?size=500&page=0").then(({ data }) => {
              this.claimProducts = data.content;
            }),
            this.$api.getRelatedObject("uploads", data).then(({ data }) => {
              this.claimUploads.existing = data._embedded.uploads;
            }),
            this.$api.getRelatedObject("participant", data).then(({ data }) => {
              this.participantSelectedToClaimOnBehalf = data;
            })
          ]).finally(() => {
            this.$forceUpdate();

            this.$refs.step2Form.validate();
            this.$refs.step3Form.validate();
          });
        });
      } else {
        return Promise.resolve();
      }
    },
    breadcrumb() {
      if (this.promotion.name) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Eligible Promotions",
            to: { name: "cprClaims" },
            exact: true
          },
          { text: "Claim - " + this.promotion.name + " (" + this.promotion.promotionKey + ")" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Eligible Promotions",
            to: { name: "cprClaims" },
            exact: true
          },
          { text: "Claim" }
        ]);
      }
    },
    onCancel() {
      if (this.$route.query.claimId != undefined && this.$route.query.claimId != 0) {
        this.$router.push({ name: "cprMyClaims", query: { tab: 2 } });
      } else {
        this.$router.push({ name: "cprClaims" });
      }
    },
    showUploadedFilesDialog() {
      this.uploadedFilesDialog = true;
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        if (item.serialNumber.startsWith("S01")) {
          item.serialNumber = item.serialNumber.substring(3);
        }
        if (item.serialNumber.length < 7) {
          item.serialNumber = item.serialNumber.padStart(7, "0");
        }
      }
    },
    onClear() {
      this.participantSelectedToClaimOnBehalf = null;
    },
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    loadFields(v) {
      this.selectedEndUser = v;
      if (v != undefined) {
        for (let claimField of this.claimFields) {
          let claimFieldId = claimField.id;
          switch (claimField.claimFieldType.name) {
            case "END_USER_POSTAL_CODE_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.postalCode);
              break;
            case "END_USER_FIRST_NAME_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.firstName);
              break;
            case "END_USER_LAST_NAME_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.lastName);
              break;
            case "END_USER_ORGANIZATION_NAME_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.organizationName);
              break;
            case "END_USER_ADDRESS1_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.address1);
              break;
            case "END_USER_ADDRESS2_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.address2);
              break;
            case "END_USER_CITY_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.city);
              break;
            case "END_USER_REGION_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.region);
              break;
            case "END_USER_COUNTRY_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.address.country);
              break;
            case "END_USER_EMAIL_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.email);
              break;
            case "END_USER_PHONE_NUMBER1_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.phoneNumber1);
              break;
            case "END_USER_PHONE_NUMBER2_TYPE":
              Vue.set(this.claimFieldValues, claimFieldId, v.phoneNumber2);
              break;
            case "END_USER_INDUSTRY":
              Vue.set(this.claimFieldValues, claimFieldId, v.industry);
              break;
          }
        }
        this.$forceUpdate();
      }
    },
    onEndUserDelete(v) {
      if (v.id == this.selectedEndUser.id) {
        this.selectedEndUser = null;
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    loadZeroSalesMonths() {
      this.zeroSalesItems = [];
      let month = moment();
      month.subtract(1, "months");
      for (let i = 0; i < 3; i++) {
        this.zeroSalesItems.push({
          text: month.format("MMMM YYYY"),
          value: month.format("YYYY-MM-[01]")
        });
        month.add(1, "months");
      }
    },
    itemBackgroundColor(item) {
      if (item.ocrPopulated) {
        return "ocrPopulated";
      }
    }
  }
};
</script>
<style>
.ocrPopulated :is(.productKeyField, span) {
  background-color: #bbdefb !important;
}
.navigation-drawer-subtitle {
  font-size: 0.8rem;
}

.flat-table tr:hover {
  background-color: transparent !important;
}
</style>
